<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="220" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="bohui(scope.row)" type="warning" size="mini">驳回</el-button>
							<el-button @click="pass(scope.row)" type="success" size="mini">通过</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<detail v-if="showtcdetail" :id="nowid" @closedetail="showtcdetail=false"></detail>
	</div>
</template>

<script>
	import detail from './detail_tc'
	export default {
		components: {
			detail
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '标题',
						width: ''
					},
					{
						prop: 'sqmytype_name',
						name: '所属类别',
						width: '150'
					},
					{
						prop: 'sqmychannel_name',
						name: '来源渠道',
						width: '150'
					},
					{
						prop: 'user_name',
						name: '发起人',
						width: '120'
					},
					{
						prop: 'state',
						name: '状态',
						width: '120',
						type: 'sqmy_type'
					},
					{
						prop: 'createtime',
						name: '创建时间',
						width: '150'
					}
				],
				tableData: [],
				showtcdetail: false,
				nowid: ''
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			showdetail(row) {
				this.nowid = row.id
				this.showtcdetail = true
			},
			bohui(row) {
				this.$prompt('请输入驳回理由', '提示', {
					confirmButtonText: '驳回',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入理由'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/sqmy/bohui',
						params: {
							id:row.id,
							sh_opinion:value
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			pass(row) {
				this.$prompt('请输入批示内容', '提示', {
					confirmButtonText: '通过',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/sqmy/tguo',
						params: {
							id:row.id,
							sh_opinion:value
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/sqmy/quanindex',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						state: 1
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'list.scss';
</style>